export const environment = {
  environmentName: 'amsterdam',
  enableProdMode: true,
  devMode: true,
  localLogs: true,
  topLevelDomain: '.app.1life.com',
  apiServer: 'https://amsterdam.app.1life.com',
  onelifeServer: 'https://amsterdam.app.1life.com',
  adminServer: 'https://amsterdam-admin.1life.com',
  chartServer: 'https://amsterdam-ui.app.1life.com',
  storybookRoute: '/design/index.html',
  oauth2: {
    providerUrl: 'https://amsterdam.app.1life.com',
    clientId: 'vVNPHVIM1txSNGgjl3ECDWya4dRcF3tcjH1fytwMwb4',
  },
  firebase: {
    useEmulator: false,
    options: {
      apiKey: 'AIzaSyB2B4iyZdrow07wgvH1jGAO3JDZ0AmWIjw',
      authDomain: 'one-medical-development.firebaseapp.com',
      databaseURL: 'https://one-medical-development.firebaseio.com',
      projectId: 'one-medical-development',
      storageBucket: 'one-medical-development.appspot.com',
      messagingSenderId: '265813166121',
      appId: '1:265813166121:web:2e71076ef9ef555513f438',
    },
    settings: {},
  },
  openTok: {
    apiKey: '45695482',
  },
  sentry: {
    enabled: true,
  },
  zoom: {
    apiKey: 'SspUrKQ0TQutl1NgWiEKRQ',
    meetingBaseUrl: 'https://zoom.us/j',
  },
  launchDarklyClientId: '605033c1a544ec0cd24ff91b',
};
